<template>
  <view-spinner :show="loading" fullscreen/>
  <div v-if="!loading" class="text-center">
    <alert-bar type="warning" message="Please login to access this page." style="width: 75%; margin: 1rem auto;"/>
    
    <q-btn
        class="login-button"
        color="homeward-purple"
        @click="onLoginClick">
      Login / Register
    </q-btn>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import { User } from 'oidc-client-ts';
import { useAuthStore } from 'stores/auth';
import AuthService from 'src/services/AuthService';
import { useRoute, useRouter } from 'vue-router';
import { useQuasar } from 'quasar';
import ViewSpinner from 'components/ViewSpinner.vue';
import { AxiosError } from 'axios';
import { handleError } from 'src/composables/ErrorHandler';
import AlertBar from 'components/AlertBar.vue';
import { useSSRContext } from 'vue';

export default defineComponent({
  name: 'LoginPage',
  components: { AlertBar, ViewSpinner },
  setup(props, { emit }) {
    const $q = useQuasar();
    const $store = useAuthStore();
    const route = useRoute();
    const router = useRouter();
    const ssrContext = process.env.SERVER ? useSSRContext() : null;
    const appRoot = process.env.SERVER
      ? ssrContext?.req.headers.host
      : window.location.origin;

    const authService = new AuthService(appRoot);
    
    const loading = ref(false);
    
    const authenticated = computed(() => $store.authenticated);
    
    function onLoginClick() {
      authService
          .signinPopup()
          .then(async (user: User) => {
            authService.setOidcUserInLocalStorage(user);
            $store.$patch((state) => {
              state.oidc_user.access_token = user.access_token;
              state.oidc_user.expires_at = user.expires_at;
              state.oidc_user.profile.sub = user.profile.sub;
            });
            await $store
                .getUserDetails()
                .then(() => {
                  if (route.query?.redirect) {
                    void router
                        .push(route.query?.redirect?.toString())
                        .catch(() => {
                          void router.push('/');
                        });
                  }
                  emit('on-login');
                })
                .catch((e: AxiosError<string>) => {
                  handleError(e, $q);
                });
          })
          .catch((e: AxiosError<string>) => {
            handleError(e, $q);
          });
    }
    
    onMounted(() => {
      loading.value = true;
      if (authenticated.value) {
        const path = route.query?.redirect?.toString() ?? '/'
        void router
            .push(path)
            .catch(() => {
              void router.push('/');
            });
      }
      loading.value = false;
    })
    
    return {
      loading,
      onLoginClick,
    }
  }
})
</script>

<style lang="scss" scoped>
.login-button {
  margin-bottom: 1rem;
}
</style>